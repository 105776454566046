const components = {
    "connected_accounts": {
        "payments": () => import('./../pages/payments/index'),
        "profile": () => import('./../pages/profile/index'),
        "activityLog": () => import('./../pages/profile/activity-log/index'),
        "changePassword": () => import('./../pages/profile/changePassword'),
        "redeemCode": () => import('./../pages/profile/redeem-code/index'),
        "paymentProfile": () => import('./../pages/payment-profile/index'),
        "addProduct": () => import('./../pages/add-products/Index'),
    },

    "refund_requests": {
        "index": () => import('./../pages/refund-requests/index'),
    },

    "promotions": {
        "benefits": () => import('./../pages/promotions/benefits'),
        "freeLicense": () => import('../pages/promotions/review/index'),
    },

    "products": {
        "elementPack": () => import('./../pages/products/element-pack/Index'),
        "primeSlider": () => import('./../pages/products/prime-slider/Index'),
        "pixelGallery": () => import('./../pages/products/pixel-gallery/Index'),
        "ultimatePostKit": () => import('./../pages/products/ultimate-post-kit/Index'),
        "ultimateStoreKit": () => import('./../pages/products/ultimate-store-kit/Index'),
        "zoloblocks": () => import('./../pages/products/zoloblocks/Index'),
        "domains": () => import('./../pages/products/LicenseDomains'),
        "downloads": () => import('./../pages/products/Downloads'),
        "verifyProductEmail": () => import('./../pages/products/VerifyProductEmail'),
    },

    "settings":{
        "downloadItems": () => import('./../pages/admin/download-items/index'),
        "refunds": () => import('./../pages/admin/refunds/index'),
        "clientBannedHost": () => import('./../pages/admin/client-banned-hosts/index'),
        "eliteLicenses": () => import('./../pages/admin/licenses/index'),
        "schedules": () => import('./../pages/admin/schedules/index'),
        "redemptions": () => import('./../pages/admin/redemptions/index'),
        "promotionalBenefits": () => import('./../pages/admin/promotional/benefits/index'),
        "promotionalReviewRequests": () => import('../pages/admin/promotional/reviews/requests/index'),
        "promotionalReviewPlatform": () => import('./../pages/admin/promotional/reviews/platforms/index'),
    }
};

export default components;
