const components = {
    "access_control": {
        "unauthenticated": {
            "register": () => import('./../pages/register/index'),
            "verification": () => import('./../pages/register/verify'),
            "login": () => import('./../pages/auth/Login'),
            "forgot": () => import('./../pages/auth/password/Email'),
            "reset": () => import('./../pages/auth/password/Reset'),
            "redemption": () => import('./../pages/redemption/index'),
            "redemption_verify": () => import('./../pages/redemption/verify'),
        },
        "dashboard": () => import('./../pages/dashboard/index'),
        "layouts": {
            'index': () => import('./../components/layout/Layout'),
            'auth_header': () => import('./../components/layout/AppHeader'),
            'sidebar_left': () => import('./../components/layout/AppSidebarLeft'),
            'auth_footer': () => import('./../components/layout/AppFooter')
        },
        "errors": {
            'error_401': () => import('./../components/errors/403'), // unauthenticated / is not loggined
            'error_403': () => import('./../components/errors/403'), // un authorization / not permissions
            'error_404': () => import('./../components/errors/404'), // page not found
            'error_500': () => import('./../components/errors/500'), // interval server errors
            'error_503': () => import('./../components/errors/500'), // maintenace
        },
        'admin':{
            'users': () => import('./../pages/admin/users/index'),
            'permissions': () => import('./../pages/admin/permissions/index'),
            'roles': () => import('./../pages/admin/roles/index')
        }
    },
};

export default components;
