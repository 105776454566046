import {createRouter, createWebHistory} from "vue-router";

// import all routes
import accessControlRoutes from "@accessControlSrc/router/routes"
import connectedAccountsRoutes from "@connectedAccountsSrc/router/routes"
import {useBdThemesAppStore} from "@accessControlSrc/stores/bdthemesApp"
import {httpResponse} from "@accessControlSrc/helpers/httpResponse";

const AllRoutes = [
    ...accessControlRoutes,
    ...connectedAccountsRoutes
];

const router = createRouter({
    history: createWebHistory(),
    routes: AllRoutes
});


router.beforeEach(async (to, from, next) => {

    // store.dispatch('CancelPendingRequest/CANCEL_PENDING_REQUESTS');
    // store.dispatch('crewlixApp/resetAbortCode');
    // Initiate Auth Store
    const appStore = useBdThemesAppStore();
    await appStore.setAbort(0);

    // if (to.name == 'products.prime.slider') {
    //     var a = document.createElement("a");
    //     a.href = "https://primeslider.pro/account/";
    //     a.target = "_blank";
    //     a.click();
    //     return
    // }

    if (to.meta.title) {
        document.title = to.meta.title;
    }

    const authRequired = to.matched.some(route => route.meta?.required_auth);
    if (!authRequired) {
        return next()
    }

    if (!appStore.isUserLoggedIn) {
        return next({
            path: '/login', query: {redirect: to.fullPath}
        });
    }

    const isRequiredPermission = to.meta?.required_permission;
    const permissions = to.meta?.permissions;

    // Get permissions if not exists in the store
    if (!appStore.getPermissions) {
        await appStore.authorization();
    }

    if (to.name == 'app.notfound') {
        await appStore.setAbort(404);
        return next()
    }

    if (!isRequiredPermission) {
        return next()
    }

    const authPermissions = appStore.getPermissions;
    if (_.intersection(permissions, authPermissions).length) {
        return next();
    } else {
        await appStore.setAbort(403);
        // await appStore.delay(2000);
        return next();
    }

    return next();
});

router.afterEach((to, from) => {
    document.querySelector('body').classList.remove('bdthemes-loading');
    document.querySelector('html').scrollTop = '0px';
})

export default router;

